var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s10 offset-s1 l10 offset-l1"},[_c('h1',[_c('i'),_vm._v(" "+_vm._s(_vm.tituloProjetos))])]),_vm._m(0),_c('div',{staticClass:"col s10 offset-s1 l10 offset-l1"},[_c('div',{staticClass:"col s12 l4 thumb"},[_vm._m(1),_c('vue-pure-lightbox',{attrs:{"thumbnail":_vm.loginPUCThumb,"images":[
              _vm.loginPUC,
              _vm.blackBoard,
              _vm.tdeCpa,
              _vm.tdeDom,
              _vm.tdeRussa,
              _vm.idMaristaCapa,
              _vm.idMarista
            ]}})],1),_c('div',{staticClass:"col s12 l4 thumb"},[_vm._m(2),_c('vue-pure-lightbox',{attrs:{"thumbnail":_vm.appThumb,"images":[
              _vm.app
            ]}})],1),_c('div',{staticClass:"col s12 l4 thumb"},[_vm._m(3),_c('vue-pure-lightbox',{attrs:{"thumbnail":_vm.bannerThumb,"images":[
              _vm.banner1,
              _vm.banner2,
              _vm.banner3
            ]}})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col s12 "},[_c('div',{staticClass:"col s5 offset-s1 divider"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',[_vm._v("web")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',[_vm._v("app")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',[_vm._v("banner")])])}]

export { render, staticRenderFns }